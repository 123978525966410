import { useState } from 'react';
import { Tab, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { IInquiry } from 'handler/inquiry/typings';
import { NavLink } from 'react-router-dom';
import NoData from 'components/no-data';
import moment from 'moment';
import DotIcon from 'assets/icons/dot.svg';
import DotBlueIcon from 'assets/icons/dot-blue.svg';
import { linkify } from 'utils/common';
import SimpleDialog from 'components/dialog-simple';
import { toast } from 'react-toastify';
import { deleteInquiry } from 'handler/inquiry';
import ButtonCustomer from 'components/button';

interface Props {
  dataUser: IInquiry[];
  onRestoreSuccess: () => void;
  onDeleteSuccess: () => void;
}

function TableUser(props: Props) {
  const { dataUser } = props;
  const [openDialog, setOpen] = useState(false);
  const [inquiryId, setInquiryId] = useState(0);

  const handleDelete = async () => {
    await deleteInquiry(inquiryId);
    setOpen(false);
    toast.success('お問い合わせは削除されました。');
    props.onDeleteSuccess();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>メールアドレス</TableCell>
            <TableCell>日付け</TableCell>
            <TableCell>質問</TableCell>
            <TableCell>ステータス</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataUser.length > 0 &&
            dataUser.map((inquiry: IInquiry, index: number) => (
              <TableRow key={index}>
                <TableCell>
                  <NavLink
                    className={'text-blue hover:underline font-bold flex flex-row items-center'}
                    to={`/inquiry-detail/` + inquiry.id}
                  >
                    <div className="w-[10px] h-[10px]">
                      {inquiry?.read_at ? (
                        <img src={DotIcon} alt="" width={10} />
                      ) : (
                        <img src={DotBlueIcon} alt="" width={10} />
                      )}
                    </div>
                    <span className="flex flex-1 pl-5">{inquiry.email}</span>
                  </NavLink>
                </TableCell>
                <TableCell className="w-40 truncate">
                  {moment(inquiry.created_at).format('YYYY/MM/DD HH:mm')}
                </TableCell>
                <TableCell>
                  <span className="max-w-96 text-ellipsis overflow-hidden span-cell">
                    <p
                      className="break-words"
                      dangerouslySetInnerHTML={{
                        __html: linkify(inquiry.content),
                      }}
                    ></p>
                  </span>
                </TableCell>
                <TableCell className="w-40 truncate">
                  {inquiry.is_answer === 1 ? '解決済み' : '未解決'}
                </TableCell>
                <TableCell>
                  <ButtonCustomer
                    onClick={() => {
                      setOpen(true);
                      setInquiryId(inquiry.id);
                    }}
                    size="small"
                    rounded={true}
                    className="bg-red text-white"
                  >
                    消去
                  </ButtonCustomer>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {dataUser.length === 0 && (
        <div>
          <NoData />
        </div>
      )}

      <SimpleDialog
        open={openDialog}
        onClose={handleClose}
        onOk={handleDelete}
        content="このお問い合わせを削除してもよろしいですか？"
      />
    </div>
  );
}

export default TableUser;
